<template>
    <div class='panel panel-default'>
        <div class='panel-heading'></div>
        <div class='row panel-body'>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Report Type</label>
                <select v-model="state.optionFields.reportType.val" class="form-control">
                    <option value="1">Customer Rebates</option>
                    <option value="2">Commission</option>
                </select>
            </div>
            <div class='date_picker col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Start Date</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.startDate.val" />
            </div>
            <div class='date_picker col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>End Date</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.endDate.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Company</label>
                <Typeahead cdi_type="company" v-bind:ID.sync="state.optionFields.companyId.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>CDI Location</label>
                <select v-model="state.optionFields.cdiLocationId.val" class="form-control">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Special Tag</label>
                <select v-model="state.optionFields.specialTag.val" class="form-control">
                    <option value="0">All</option>
                    <option v-for="[id, tag] in cache.userCache.USER_SPECIAL_TAGS" v-bind:key="id" :value="id">{{ tag }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import userCache from '@/cache/user.cache.js'
    import Typeahead from '@/components/utils/typeaheads/Typeahead';
    import DatePicker from "@/components/utils/DatePicker";

    export default {
        name: "Options",
        components: { DatePicker, Typeahead },

        data() {
            return {
                state: store.state,
                toggleHide: true,
                cache: {
                    cdiLocationCache,
                    userCache
                }
            }
        }
    }
</script>

<style scoped>

</style>